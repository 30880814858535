/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import cn from 'classnames';

import Text from '@/components/atoms/Text';
import Divider from '@/components/atoms/Divider';

import styles from './index.module.scss';
import Button from '@/components/atoms/Button';

const Title = (props) => {
  const {
    finalLink,
    className = '',
    label = '',
    icon = null,
    iconEnd = false,
    labelCase = 'none',
    labelWeight = 'bolder',
    labelColor = 'white',
    finalLabel = '',
    finalLabelCase = 'none',
    finalLabelWeight = 'normal',
    finalLabelColor = 'black',
    underlined = true,
    dividerSeparation = null,
    variant = 'p',
    iconClick = () => {},
    labelSize = 'm'
  } = props;
  const titleClass = cn({
    [styles.title]: true,
    [styles.noIcon]: !icon,
    [styles.iconEnd]: iconEnd
  });

  return (
    <div className={`${styles.titleWrapper} ${className}`}>
      <div className={styles.titleContainer}>
        <div
          className={titleClass}
          style={{ paddingBottom: dividerSeparation }}
        >
          {!iconEnd && icon && (
            <Button
              id={`${label} firstIcon`}
              onClick={iconClick}
              startIcon={icon}
              width="none"
              withOutPadding
              style={{ height: 'auto' }}
            />
          )}
          {label && (
            <Text
              weight={labelWeight}
              textCase={labelCase}
              textColor={labelColor}
              variant={variant}
              textSize={labelSize}
            >
              {label}
            </Text>
          )}
          {iconEnd && icon && (
            <Button
              id={`${label} endIcon`}
              onClick={iconClick}
              startIcon={icon}
              width="none"
              withOutPadding
            />
          )}
        </div>
        {finalLabel && (
          <Text
            weight={finalLabelWeight}
            textCase={finalLabelCase}
            textColor={finalLabelColor}
            variant="span"
          >
            {finalLabel}
          </Text>
        )}
        {finalLink && { finalLink }}
      </div>
      {underlined && <Divider color="#707070" />}
    </div>
  );
};

Title.propTypes = {
  finalLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  finalLabelCase: PropTypes.oneOf(['uppercase', 'capitalize', 'none']),
  finalLabelWeight: PropTypes.oneOf(['bold', 'bolder', 'normal']),
  finalLabelColor: PropTypes.string,
  icon: PropTypes.node,
  iconEnd: PropTypes.bool,
  labelCase: PropTypes.oneOf(['uppercase', 'capitalize', 'none']),
  labelWeight: PropTypes.oneOf(['bold', 'bolder', 'normal']),
  labelColor: PropTypes.string,
  label: PropTypes.string,
  underlined: PropTypes.bool,
  dividerSeparation: PropTypes.string,
  variant: PropTypes.string,
  iconClick: PropTypes.func,
  labelSize: PropTypes.string,
  finalLink: PropTypes.object
};

export default Title;
